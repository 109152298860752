// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---docs-concept-mdx": () => import("./../../docs/Concept.mdx" /* webpackChunkName: "component---docs-concept-mdx" */),
  "component---docs-index-mdx": () => import("./../../docs/index.mdx" /* webpackChunkName: "component---docs-index-mdx" */),
  "component---docs-konfiguration-mdx": () => import("./../../docs/Konfiguration.mdx" /* webpackChunkName: "component---docs-konfiguration-mdx" */),
  "component---docs-tradeoffs-mdx": () => import("./../../docs/Tradeoffs.mdx" /* webpackChunkName: "component---docs-tradeoffs-mdx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

