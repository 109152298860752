module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/vercel/33cbcb6e/node_modules/gatsby-theme-docz/src/base/Layout.js"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{"colors":{"primary":"#F653A9","sidebar":{"navLinkActive":"#F653A9"},"header":{"button":{"bg":"#F653A9"}},"props":{"highlight":"#F653A9"},"modes":{"dark":{"primary":"#F653A9","sidebar":{"navLinkActive":"#F653A9"},"header":{"button":{"bg":"#F653A9"}},"props":{"highlight":"#F653A9"}}}}},"src":"./","gatsbyRoot":null,"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{},"menu":[],"mdPlugins":[],"hastPlugins":[],"ignore":["readme.md"],"typescript":true,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":null,"o":null,"open":null,"open-browser":null,"root":"/vercel/33cbcb6e/.docz","base":"/","source":"./","gatsby-root":null,"files":"**/*.{md,markdown,mdx}","public":"/public","dest":"/public","d":".docz/dist","editBranch":"master","eb":"master","edit-branch":"master","config":"","title":"NeoAnalytics 👩‍🎤","description":"My awesome app using docz","host":"localhost","port":3000,"p":3000,"separator":"-","paths":{"root":"/vercel/33cbcb6e","templates":"/vercel/33cbcb6e/node_modules/docz-core/dist/templates","docz":"/vercel/33cbcb6e/.docz","cache":"/vercel/33cbcb6e/.docz/.cache","app":"/vercel/33cbcb6e/.docz/app","appPackageJson":"/vercel/33cbcb6e/package.json","appTsConfig":"/vercel/33cbcb6e/tsconfig.json","gatsbyConfig":"/vercel/33cbcb6e/gatsby-config.js","gatsbyBrowser":"/vercel/33cbcb6e/gatsby-browser.js","gatsbyNode":"/vercel/33cbcb6e/gatsby-node.js","gatsbySSR":"/vercel/33cbcb6e/gatsby-ssr.js","importsJs":"/vercel/33cbcb6e/.docz/app/imports.js","rootJs":"/vercel/33cbcb6e/.docz/app/root.jsx","indexJs":"/vercel/33cbcb6e/.docz/app/index.jsx","indexHtml":"/vercel/33cbcb6e/.docz/app/index.html","db":"/vercel/33cbcb6e/.docz/app/db.json"}},
    }]
